import { useEffect, useState } from 'react';
import { AppCommonProps } from 'types/app';
import config from '~/config';
import { withI18n } from '~/hocs';

const Chatbot = ({ className }: AppCommonProps) => {
  useEffect(() => {
    const div = document.createElement('div');
    div.setAttribute('get-ac-chat', 'true');

    const script = document.createElement('script');
    script.src = `${config.chatBotURL}/chat-embed.js`;
    script.defer = true;

    document.body.appendChild(div);
    document.body.appendChild(script);

    const setChatbotPosition = () => {
      const adsbygoogle = document.querySelector('.adsbygoogle-noablate[data-anchor-status="displayed"]');
      const chatbotIcon = document.querySelector('.ac-chat-bot-icon');
      const bottomOffset = adsbygoogle?.style?.bottom ? 20 : 135;
      chatbotIcon?.style?.bottom = `${bottomOffset}px`;
      chatbotIcon?.style?.transition = '.3s';
      chatbotIcon?.style?.display = 'block';
    };

    const handleEvents = () => {
      setChatbotPosition();
    };
    const handleEventsScrool = () => {
      const adsbygoogle = document.querySelector('.adsbygoogle-noablate[data-anchor-status="displayed"]');
      const chatbotIcon = document.querySelector('.ac-chat-bot-icon');
      const bottomOffset = adsbygoogle?.style?.bottom ? 135 : 20;
      chatbotIcon?.style?.bottom = `${bottomOffset}px`;
      chatbotIcon?.style?.transition = '.3s';
      chatbotIcon?.style?.display = 'block';
    };

    window.addEventListener('load', handleEvents);
    window.addEventListener('scroll', handleEventsScrool);
    window.addEventListener('resize', handleEvents);

    document.addEventListener('click', (e) => {
      if (e.target.closest('.adsbygoogle-noablate')) {
        setTimeout(() => {
          handleEvents();
        }, 100);
      } else {
        handleEvents();
      }
    });

    return () => {
      window.removeEventListener('load', handleEvents);
      window.removeEventListener('scroll', handleEvents);
      window.removeEventListener('resize', handleEvents);
      document.removeEventListener('click', handleEvents);
    };
  }, []);

  return <div className={className}></div>;
};

export default withI18n(Chatbot);
